<template>
    <div class="mt-5 mb-5 w-full h-20 bg-white rounded-xl ring-opacity-50 shadow-xl text-center p-4 hover:bg-orange-tint group cursor-pointer mx-auto transition duration-300 ease"
        :class="{ 'bg-orange-tint': selected, 'bg-opacity-25': day.slots.length === 0, 'text-gray-300': day.slots.length === 0 }">
        <p class="text-orange text-extralight mb-2 font-poppins font-medium text-sm leading-5">{{ day.slots.length }}
            slots</p>
        <p class=" capitalize group-hover:text-orange font-poppins font-medium text-sm leading-5"
            :class="{ 'text-orange': selected, }">{{ day.day }}, {{ this.months[new Date(this.day.date).getMonth()] }} {{
                    new Date(this.day.date).getDate()
            }}</p>
        <!-- <p class="font-bold group-hover:text-orange" :class="{'text-orange' : selected,}">{{ this.months[new Date(this.day.date).getMonth()] }} {{ new Date(this.day.date).getDate() }}</p> -->
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selected: Boolean,
        day: Object,
        hours: Array
    },
    data() {
        return {
            months: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
        }
    },
}
</script>
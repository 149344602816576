<template>
  <page-layout linkDefault="/" title="Pick a time" :backButton="true">
    <div class="flex h-screen">
      <div class="w-full m-5 lg:w-64 no-scrollbar">

        <transition-group name="scale-up" appear tag="ul">
          <li v-for="(day, index) in tutor_previewed.schedule" :key="index" class="mx-auto">
            <book-day @click="setDay(day)
            " :day="day" :hours="sort(this.previewed_slot.slots)" :selected="this.previewed_slot == day" />

            <transition-group :key="updateDay" v-if="(this.previewed_slot.day === day.day)" name="scale-up" appear tag="ul"
              class="relative flex justify-center flex-wrap">
          <li v-for="(slot, index) in day.slots" :key="index">
            <book-hour :hour="slot" @click="this.previewed_slot.time = slot"
              :selected="(this.previewed_slot.time === slot && this.previewed_slot.day === day.day)" />
          </li>
        </transition-group>
        </li>
        </transition-group>
      </div>
    </div>
    <div class="w-52 lg:96">
      <div class="">
      </div>
    </div>

    <transition name="fade-up-left" appear>
      <div class="fixed bottom-24 right-5">
        <Button v-if="previewed_slot.day != '' && previewed_slot.time != null" text="session info" type="primary"
          @click="pickTime()" />
      </div>
    </transition>
  </page-layout>
</template>

<script>
import PageLayout from "../components/base/pageLayout.vue";
import BookDay from "../components/BookDay.vue";
import BookHour from "../components/BookHour.vue";
import { mapState, mapMutations } from "vuex";
import { useRouter } from "vue-router";
import Button from "../components/button.vue";
import mixpanel from 'mixpanel-browser';
import { readFromLocalStorage } from '../helpers';

export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  created() {
    this.loadSessionInfo();
    mixpanel.track("visited book class", {
      tutor: this.tutor_previewed.user.username,
      ...this.session_previewed,
    });
  },
  computed: {
    ...mapState(["tutor_previewed", "session_previewed"]),
  },
  components: {
    PageLayout,
    BookDay,
    BookHour,
    Button,
  },
  data() {
    return {
      previewed_slot: {
        time: null,
        day: "",
      },
      updateDay: 0,
      hours: ['a', 'b', 'c']
    };
  },
  methods: {
    ...mapMutations(["SetSessionPreviewTime", "loadSessionInfo"]),
    pickTime() {
      this.SetSessionPreviewTime(this.previewed_slot);
      this.$router.replace("session_info");
    },
    setDay(day) {
      this.previewed_slot = day;
      this.previewed_slot.time = null;
      this.updateDay++;
    },
    sort(arr, diff = (a, b) => a - b) {
      if (!arr) return [];
      return arr.sort(diff);
    },
  },
  name: "PickTime",
};
</script>

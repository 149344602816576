<template>
    <div class="m-1 rounded-full w-24 h-12 bg-white ring-primary ring-1 flex items-center cursor-pointer group hover:bg-orange-tint" :class="{'bg-orange-tint' : selected, 'ring-2' : selected, 'ring-orange' : selected}">
        <p class="mx-auto group-hover:text-orange" :class="{'text-orange' : selected}"><b>{{slot}}</b></p>
    </div>
  
</template>

<script>
    export default {
        props: {
            selected: Boolean,
            hour: Number
        },
        computed: {
            slot() {
                if (this.hour == 0) return '12 am'
                if (this.hour >= 12) return (this.hour - 12 || 12) + ' pm';
                return this.hour + ' am'
            }
        },
    }
</script>